import { MembershipApi } from '@wix/ambassador-membership-api/http';
import { PricingPlansReadApi, ListPublicPlansRequest } from '@wix/ambassador-pricing-plans-read-api/http';

type PricingPlansReadApi = ReturnType<typeof PricingPlansReadApi>;
type PlansService = ReturnType<PricingPlansReadApi['PlansService']>;

type MembershipApi = ReturnType<typeof MembershipApi>;
type OrdersService = ReturnType<MembershipApi['OrdersService']>;
type PremiumService = ReturnType<MembershipApi['PremiumService']>;

export const createMembershipApi = (baseUrl: string) => MembershipApi(baseUrl + '/_api/paid-plans');

export const createPricingPlansReadApi = (baseUrl: string) => PricingPlansReadApi(baseUrl + '/_api/paid-plans');

export class PricingPlansApi {
  protected headers: Record<string, string> = {
    Authorization: '',
    'Content-Type': 'application/json',
  };
  protected plansService: PlansService;
  protected ordersService: OrdersService;
  protected premiumService: PremiumService;

  constructor(headers: Record<string, string>, membershipApi: MembershipApi, pricingPlansReadApi: PricingPlansReadApi) {
    this.headers = { ...this.headers, ...headers };
    this.plansService = pricingPlansReadApi.PlansService();
    this.ordersService = membershipApi.OrdersService();
    this.premiumService = membershipApi.PremiumService();
  }

  public updateInstance(newInstance: string) {
    this.headers.Authorization = newInstance;
  }

  public async loadPaidPlans(request: ListPublicPlansRequest = {}) {
    const { plans } = await this.plansService(this.headers).listPublicPlans(request);
    return plans || [];
  }

  public createOrder(planId: string) {
    return this.ordersService(this.headers).purchasePlan({ planId });
  }

  public getPremiumStatus() {
    return this.premiumService(this.headers).getPremiumStatus({});
  }

  public async getPremiumMessageDisplayStatus() {
    const res = await this.getPremiumStatus();
    return !res?.acceptPaymentsEnabled && !res?.hasOldPlans;
  }
}
